// Core
import { useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
// Parts
import UserTypesFilter from './component/UserTypesFilter';
import DateRangeFilter from '../DateRangeFilter';
import Select from '../../_Form/Selects/Select/Select';
import AttributeSetsContextTableFilter from './component/AttributeSetsContextTableFilter';
import TableHeadFilterCell from './component/TableHeadFilterCell/TableHeadFilterCell';
import UsersContextTableFilter from './component/UsersContextTableFilter';
import BillingStatusTableFilter from './component/BillingStatusTableFilter';
import LetterTemplateEventFilter from './component/LetterTemplateEventFilter';
import LetterTemplateRolesFilter from './component/LetterTemplateRolesFilter';
import LetterTemplateGroupsFilter from './component/LetterTemplateGroupsFilter';
import NotificationsGroupsFilter from './component/NotificationsGroupsFilter';
import NotificationsTypesFilter from './component/NotificationsTypesFilter';
import PartnerRolesFilter from './component/PartnerRolesFilter';
import PriceTableFilter from './component/PriceTableFilter';
import TextFieldTableFilter from './component/TextFieldTableFilter';
import DecimalTableFilter from './component/DecimalTableFilter';
import MessageStatusFilter from './component/MessageStatusFilter';
import StatusNameFilter from './component/StatusNameFilter';
import SourceSelectFilter from './component/SourceSelectFilter';
import TypeFilter from './component/TypeFilter';
import MultipleFilter from './component/MultipleFilter';
import CategoriesTreeFilter from './component/CategoriesTreeFilter';
// Data
import { tableFiltersTypes } from '../../../../_helpers/data/pages/contentMenagement/contentProducts/tableFilters';
import { tableDataTypes } from '../../../../_helpers/data/tableDataTypes';
import { tableColumns as brandsTableColumns } from '../../../../_helpers/data/pages/handbook/brands/tableColumns';
import { tableColumns as orderTableColumns } from '../../../../_helpers/data/pages/orders/tableColumns';
import { tableColumns as logisticsDataTableColumns } from '../../../page/Products/LogisticsData/components/LogisticsDataTable/tableColumns';
// Engine
import { selectors } from '../../../../engine/config/selectors';
import { uiActions } from '../../../../engine/core/ui/action';
// Style
import TableFilterStyle from './TableFilterStyle';
import { useStyles } from '../../../hooks/useStyles';
import { COMPONENTS_TYPES } from '../../../containers/App/AppStyles';
import PartnerFilter from './component/PartnerFilter';
import BusinessFilter from './component/BusinessFilter';
import { cancellationReasons, serviceRequestsStatuses } from '../../../../engine/config/serviceRequestsStatuses';
import { options as bankInstallmentOptions } from '../../../page/PartnerAccess/components/BankInstallmentsTable/utils';

const { TABLE_CELL_FILTER } = COMPONENTS_TYPES;

const TableFilter = (props) => {
  const {
    column, filteringEnabled,
    filter, onFilter, defaultFilterValue,
  } = props;

  const classes = useStyles(TableFilterStyle);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const path = useSelector(selectors.router.pathname);
  const currentFiltersTypeList = useSelector(selectors.ui.currentFiltersTypeList);
  const currentFiltersNameList = useSelector(selectors.ui.currentFiltersNameList);

  const setInCurrentFiltersTypeList = (name) => {
    dispatch(uiActions.setInCurrentFiltersTypeList(name));
  };

  const setInCurrentFiltersNameList = (name) => {
    dispatch(uiActions.setInCurrentFiltersNameList(name));
  };


  const changeValue = (event) => {
    const value = event.target ? event.target.value : event?.value;
    return (
      onFilter(isNil(value) ? null : { value })
    );
  };

  const changeSelectFilter = (orderStatus) => {
    onFilter({ columnName: column.name, value: orderStatus });
  };

  const getValue = (defaultValue = undefined) => get(filter, 'value', defaultValue);

  const getKey = (prefix = '') => {
    const value = get(filter, 'value');
    switch (true) {
      case Array.isArray(value): {
        const checkIsArr = (Array.isArray(value) && value?.length > 1)
          ? value.map(item => item.value).join('-')
          : value?.[0]?.value;
        return `${prefix}key_${column.name}${checkIsArr}`;
      }
      case typeof value === 'object': {
        return `${prefix}key_${column.name}${JSON.stringify(value)}`;
      }
      default: {
        return `${prefix}key_${column.name}${value}`;
      }
    }
  };

  const onSelectFilterChange = (filterValue) => {
    onFilter({ columnName: column.name, value: filterValue });
  };

  const onSelectTagsFilterChange = (filterValue) => {
    onFilter({ columnName: column.name, value: [filterValue] });
  };

  const resetFilter = () => {
    onFilter({
      columnName: column.name,
      value: defaultFilterValue === ''
        ? defaultFilterValue
        : null,
    });
  };

  const hasNoValue = () => {
    if (!filteringEnabled) {
      return true;
    }

    const value = getValue();

    switch (typeof value) {
      case 'object': {
        return isEmpty(value);
      }
      case 'number': {
        return (value === 0) ? false : !value;
      }
      default: {
        return isNil(value) || !value;
      }
    }
  };

  const disabled = hasNoValue();
  const key = getKey();

  useEffect(() => {
    switch (column.type) {
      case tableDataTypes.categories.type:
      case tableFiltersTypes.category: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.attributeSets.type: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.partnerPaymentTypes.name: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.faqStatus.name: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.configurationsType.name: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.messageStatus.type: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.faqServiceDescStatus.name: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableFiltersTypes.feedStatus: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.ordersPaymentType.type: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.ordersShippingType.type: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableFiltersTypes.feedCategoryStatus: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableFiltersTypes.feedProductsStatus: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableFiltersTypes.feedProductsElaborationStatus: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableFiltersTypes.contentManager: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableFiltersTypes.manager: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableFiltersTypes.feedPriority: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.adminManager.type: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.partnerPaymentTypeTransactionStatuses.type: {
        if (!currentFiltersTypeList.includes(column.type)) {
          setInCurrentFiltersTypeList(column.type);
        }
        break;
      }
      case tableDataTypes.productFeedCategoryStatus.type: {
        break;
      }
      default: {
        if (!currentFiltersNameList.includes(column.name)) {
          setInCurrentFiltersNameList(column.name);
        }
      }
    }
  }, [column.type, column.name, currentFiltersTypeList, currentFiltersNameList]);

  switch (column.type) {
    case tableDataTypes.feedProductQuantity.type:
    case tableDataTypes.feedAttributesQuantity.type: {
      return <TableHeadFilterCell clearButton={false} disabled />;
    }
    case tableDataTypes.mailTemplateEventName.name: {
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
          <LetterTemplateEventFilter
            filters={filter}
            changeSelectFilter={changeSelectFilter}
          />
        </TableHeadFilterCell>
      );
    }
    case tableDataTypes.mailTemplateGroup.name: {
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
          <LetterTemplateGroupsFilter
            filters={filter}
            changeSelectFilter={onSelectTagsFilterChange}
          />
        </TableHeadFilterCell>
      );
    }
    case tableDataTypes.notificationGroup.name: {
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
          <NotificationsGroupsFilter
            filters={filter}
            changeSelectFilter={onSelectTagsFilterChange}
          />
        </TableHeadFilterCell>
      );
    }
    case tableDataTypes.notificationType.name: {
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
          <NotificationsTypesFilter
            filters={filter}
            changeSelectFilter={onSelectTagsFilterChange}
            isClearable={false}
          />
        </TableHeadFilterCell>
      );
    }
    case tableDataTypes.mailTemplateRoles.name: {
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
          <LetterTemplateRolesFilter
            filters={filter}
            changeSelectFilter={onSelectTagsFilterChange}
          />
        </TableHeadFilterCell>
      );
    }
    case tableDataTypes.number.type: {
      return (
        <TextFieldTableFilter
          disabled={!filteringEnabled}
          disabledIcon={disabled}
          key={key}
          className={cx(classes.textFieldNumberHideArrowsChrome, classes.textFieldNumberHideArrowsFirefox)}
          filterValue={getValue('')}
          setFilterValue={onSelectFilterChange}
          resetFilter={resetFilter}
          regularExpr={/[^0-9.]/g}
        />
      );
    }
    case tableDataTypes.phone.type:
    case tableFiltersTypes.media:
    case tableFiltersTypes.sku:
    case tableFiltersTypes.text:
    case tableFiltersTypes.textArea:
    case tableFiltersTypes.partnerSku: {
      let inputProps = {};
      let type = 'text';
      let isWithCategoryId = false;
      let defaultKey = getKey();
      let filterValue = getValue('');

      if (column.name === 'feed') {
        const currentId = path.substring(path.lastIndexOf('/') + 1);
        isWithCategoryId = path.indexOf(`/categories/${currentId}`) !== -1;
        defaultKey = getKey(currentId);
        filterValue = isWithCategoryId ? currentId : filterValue;
      }

      if (column.type === tableDataTypes.phone.type) {
        type = 'tel';
      }

      if (column.type === tableFiltersTypes.media) {
        type = 'number';
        inputProps = { min: 0 };
      }
      if (column.name === 'business') {
        const filters = { value: getValue([]) };
        return (
          <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
            <BusinessFilter
              key={key}
              disabled={!filteringEnabled}
              filters={filters}
              onSelectChange={onSelectFilterChange}
              columnName={column.name}
            />
          </TableHeadFilterCell>
        );
      }
      return (
        <TextFieldTableFilter
          disabled={!filteringEnabled}
          disabledIcon={disabled}
          key={defaultKey}
          filterValue={filterValue}
          setFilterValue={onSelectFilterChange}
          resetFilter={resetFilter}
          inputProps={inputProps}
          type={type}
        />
      );
    }
    case tableFiltersTypes.category: {
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
          <CategoriesTreeFilter
            filters={filter}
            closeMenuOnScroll={column.closeMenuOnScroll}
            onSelectChange={onSelectFilterChange}
            columnType={column.type}
          />
        </TableHeadFilterCell>
      );
    }
    case tableDataTypes.faqServiceDescStatus.type:
    case tableFiltersTypes.partnerStatus:
    case tableFiltersTypes.feedPriority:
    case tableFiltersTypes.feedCategoryStatus:
    case tableFiltersTypes.feedProductsStatus:
    case tableFiltersTypes.feedProductsElaborationStatus:
    case tableDataTypes.partnerPaymentTypes.type:
    case tableDataTypes.ordersPaymentType.type:
    case tableDataTypes.ordersShippingType.type:
    case tableDataTypes.configurationsType.type:
    case tableFiltersTypes.feedStatus:
    case tableDataTypes.faqStatus.type:
    case tableFiltersTypes.multipleSelect:
    case tableFiltersTypes.productStatus:
    case tableFiltersTypes.productCancelStatus:
    case tableDataTypes.partnerPaymentTypeTransactionStatuses.type:
    case tableDataTypes.partnerCancelStatus.type:
    case tableDataTypes.feedType.type:
    case tableDataTypes.brand.type:
    case tableDataTypes.productFeedCategoryStatus.type:
    case tableDataTypes.productFeedAttributeStatus.type:
    case tableFiltersTypes.faqServiceDescQuestionType: {
      const filters = { value: getValue() };

      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
          <MultipleFilter
            closeMenuOnScroll={column.closeMenuOnScroll}
            columnType={column.type}
            columnName={column.name}
            columnValues={column.values}
            key={key}
            disabled={!filteringEnabled}
            filters={filters}
            onSelectChange={onSelectFilterChange}
          />
        </TableHeadFilterCell>
      );
    }
    case tableFiltersTypes.recipientVerificationStatus:
    case tableFiltersTypes.boolean:
    case tableFiltersTypes.enabled:
    case tableFiltersTypes.select:
    case tableDataTypes.xmlFileStatuses.type:
    case tableDataTypes.commissionType.type:
    case tableDataTypes.ignored.type:
    case tableDataTypes.attributeIgnored.type:
    case tableDataTypes.partnerEditEnabled.type:
    case tableDataTypes.enabledInIPrice.type:
    case tableDataTypes.select.type: {
      let options = column.values || [
        { value: 0, label: t('Нет') },
        { value: 1, label: t('Да') },
      ];
      let selectWrapper = true;
      let inputWrapper = false;

      if (column.name === 'notCallMe') {
        options = [
          { value: 0, label: '-' },
          { value: 1, label: t('Да') },
        ];
        selectWrapper = false;
        inputWrapper = true;
      }
      if (column.name === 'isTop') {
        options = [
          { value: 1, label: t('Топ') },
          { value: 0, label: t('Не Топ') },
        ];
        selectWrapper = false;
        inputWrapper = true;
      }

      if (column.name === 'dictionaryType') {
        options = [
          { value: 'feature', label: t('feature') },
          { value: 'feature_value', label: t('feature_value') },
        ];
      }

      if (column.name === 'paymentCount') {
        options = bankInstallmentOptions;
      }

      if (column.name === 'status') {
        options = [
          { value: serviceRequestsStatuses.AWAITING_APPROVAL, label: 'Очікує розгляду' },
          { value: serviceRequestsStatuses.AWAITING_PAYMENT, label: 'Очікує оплати' },
          { value: serviceRequestsStatuses.SIGNING_THE_CONTRACT, label: 'Підписання договору' },
          { value: serviceRequestsStatuses.CANCELED, label: 'Скасовано' },
          { value: serviceRequestsStatuses.CONNECTED, label: 'Підключено' },
          { value: serviceRequestsStatuses.DISABLED, label: 'Вимкнено' },
        ];
        selectWrapper = false;
        inputWrapper = true;
      }

      if (column.name === 'cancellationReason') {
        options = [
          { value: cancellationReasons.CANCEL_REASON_CONDITION, label: 'Не влаштовують умови організації перевезень і видачі замовлень' },
          { value: cancellationReasons.CANCEL_REASON_LOCATION, label: 'Немає пунктів прийому/видачі замовлень у моєму місті' },
          { value: cancellationReasons.CANCEL_REASON_OTHER, label: 'Інше' },
        ];
        selectWrapper = false;
        inputWrapper = true;
      }

      if (column.name === 'serviceName') {
        options = [
          { value: 'ALLO_EXPRESS', label: t('АЛЛО Express') },
        ];
        selectWrapper = false;
        inputWrapper = true;
      }

      const isAutoField = [
        tableDataTypes.featureAutoUpdate.name,
        tableDataTypes.priceAutoUpdate.name,
      ].includes(column.name);

      if (isAutoField) {
        options = [
          { value: 0, label: t('Ручное') },
          { value: 1, label: t('Авто') },
        ];
      }

      if (
        column.type === tableFiltersTypes.enabled
        || column.type === tableFiltersTypes.commissionType
      ) {
        options = [
          { value: 0, label: t('Откл') },
          { value: 1, label: t('Вкл') },
        ];
        selectWrapper = false;
        inputWrapper = true;
      }

      if (
        column.name === tableDataTypes.creditLimitStatus.name
      ) {
        options = [
          { value: 0, label: t('Выкл') },
          { value: 1, label: t('Вкл') },
        ];
      }

      if (column.type === tableFiltersTypes.recipientVerificationStatus) {
        options = [
          { value: 1, label: t('Совпадает') },
          { value: 2, label: t('Не совпадает') },
        ];
      }

      if (column.type === tableDataTypes.xmlFileStatuses.type) {
        options = [
          { value: 1, label: t('В очереди') },
          { value: 2, label: t('Готов') },
          { value: 3, label: t('Новый') },
        ];
      }
      if (column.type === tableDataTypes.commissionType.type) {
        options = [
          { value: 1, label: t('Общая комиссия') },
          { value: 2, label: t('Индивидуальная комиссия') },
        ];
      }

      return (
        <TableHeadFilterCell
          cellType={TABLE_CELL_FILTER.selectFilter}
          selectWrapper={selectWrapper}
          disabled={disabled}
          inputWrapper={inputWrapper}
          resetFilter={resetFilter}
        >
          <Select
            closeMenuOnScroll={column.closeMenuOnScroll}
            handleChange={changeValue}
            options={options}
            value={getValue()}
            filterValueBy="value"
            label=""
            labelWidth={0}
            formSelectWidth="100%"
            wrapperClassName={cx(classes.flexGrowOne, classes.selectTableHead)}
            variant="standard"
          />
        </TableHeadFilterCell>
      );
    }
    case tableFiltersTypes.sourceType: {
      return (
        <TableHeadFilterCell
          cellType={TABLE_CELL_FILTER.selectFilter}
          disabled={disabled}
          resetFilter={resetFilter}
        >
          <SourceSelectFilter
            handleChange={changeValue}
            column={column}
            getValue={getValue}
            wrapperClassName={classes.flexGrowOne}
          />
        </TableHeadFilterCell>
      );
    }
    case tableDataTypes.available.type: {
      const options = [
        { value: 0, label: t('Нет') },
        { value: 1, label: t('Есть') },
      ];

      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
          <Select
            handleChange={changeValue}
            options={options}
            value={getValue()}
            filterValueBy="value"
            label=""
            labelWidth={0}
            formSelectWidth="100%"
            wrapperClassName={classes.flexGrowOne}
          />
        </TableHeadFilterCell>
      );
    }
    case tableFiltersTypes.dateTimeStringFromObject:
    case tableFiltersTypes.datetime:
    case tableFiltersTypes.datetimeNumber:
    case tableFiltersTypes.dateString:
    case tableFiltersTypes.date: {
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.dateFilter} disabled={disabled} resetFilter={resetFilter}>
          <DateRangeFilter
            range
            clearButton={false}
            filters={filter}
            onChange={onSelectFilterChange}
            columnName={column.name}
          />
        </TableHeadFilterCell>
      );
    }
    case tableDataTypes.dateSingle.type: {
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.dateFilter} disabled={disabled} resetFilter={resetFilter}>
          <DateRangeFilter
            range={false}
            clearButton={false}
            filters={filter}
            onChange={onSelectFilterChange}
          />
        </TableHeadFilterCell>
      );
    }
    case tableDataTypes.dateMonth.type: {
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.dateFilter} disabled={disabled} resetFilter={resetFilter}>
          <DateRangeFilter
            type="month"
            clearButton={false}
            filters={filter}
            onChange={onSelectFilterChange}
          />
        </TableHeadFilterCell>
      );
    }
    case tableFiltersTypes.rating:
    case tableFiltersTypes.decimal: {
      return (
        <DecimalTableFilter
          disabled={disabled}
          key={key}
          filterValue={getValue('')}
          setFilterValue={onSelectFilterChange}
          resetFilter={resetFilter}
        />
      );
    }
    case tableFiltersTypes.price: {
      const name = column.name.toString();
      return (
        <PriceTableFilter
          name={name}
          disabled={disabled}
          key={key}
          filterValue={getValue('')}
          setFilterValue={onSelectFilterChange}
          resetFilter={resetFilter}
        />
      );
    }
    case tableDataTypes.contentManager.type:
    case tableDataTypes.adminManager.type:
    case tableDataTypes.assignedTo.type: {
      const filterIsShow = column.filter === undefined ? true : column.filter;// disabled filter from gridSettings
      let context;
      let role;

      switch (column.name) {
        case tableDataTypes.adminManager.type: {
          context = 'select';
          role = '1,2';
          break;
        }
        default: {
          break;
        }
      }
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
          <UsersContextTableFilter
            columnName={column.name}
            columnType={column.type}
            columnBulkEditable={column.bulkEditable}
            disabled={!filterIsShow}
            key={key}
            filters={filter || {}}
            onSelectChange={onSelectFilterChange}
            context={context}
            role={role}
          />
        </TableHeadFilterCell>
      );
    }
    case tableDataTypes.attributeSets.type: {
      return (
        <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
          <AttributeSetsContextTableFilter
            disabled={!filteringEnabled}
            key={key}
            filters={filter || {}}
            onSelectChange={onSelectFilterChange}
            columnType={column.type}
          />
        </TableHeadFilterCell>
      );
    }
    default: {
      switch (column.name) {
        case tableDataTypes.author.name: {
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
              <UsersContextTableFilter
                columnName={column.name}
                columnType={column.type}
                columnBulkEditable={column.bulkEditable}
                key={key}
                filters={filter || {}}
                onSelectChange={onSelectFilterChange}
                isAuthor={column.name === 'author'}
              />
            </TableHeadFilterCell>
          );
        }
        case tableDataTypes.messageUsers.name: {
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
              <UserTypesFilter
                filters={filter}
                onSelectChange={changeSelectFilter}
              />
            </TableHeadFilterCell>
          );
        }
        case tableDataTypes.messageStatus.name: {
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
              <MessageStatusFilter
                filters={filter}
                onSelectChange={changeSelectFilter}
                columnType={column.type}
              />
            </TableHeadFilterCell>
          );
        }
        case 'createdAt':
        case 'answeredAt':
        case tableDataTypes.confirmedDate.type:
        case 'managerCreatedAt':
        case 'finishData':
        case tableDataTypes.sentAt.name:
        case orderTableColumns.orderedAt.name:
        case orderTableColumns.canceledAt.name:
        case orderTableColumns.doneAt.name:
        case orderTableColumns.datetime.name:
        case orderTableColumns.shippedAt.name:
        case 'updatedAt': {
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.dateFilter} disabled={disabled} resetFilter={resetFilter}>
              <DateRangeFilter
                disabled={!filteringEnabled}
                clearButton={false}
                filters={filter}
                onChange={onSelectFilterChange}
              />
            </TableHeadFilterCell>
          );
        }
        case tableDataTypes.categories.type:
        case tableFiltersTypes.category: {
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
              <CategoriesTreeFilter
                closeMenuOnScroll={column.closeMenuOnScroll}
                filters={filter}
                onSelectChange={onSelectFilterChange}
                columnType={column.type}
              />
            </TableHeadFilterCell>
          );
        }
        case 'type': {
          const filterValue = getValue(null);
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
              <TypeFilter
                filters={filterValue}
                onChange={onSelectTagsFilterChange}
              />
            </TableHeadFilterCell>
          );
        }
        case tableDataTypes.partnerRoles.type: {
          const filters = { value: getValue([]) };
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
              <PartnerRolesFilter
                key={key}
                frontTranslate={tableDataTypes.partnerRoles.frontTranslate}
                disabled={!filteringEnabled}
                onSelectChange={onSelectFilterChange}
                filters={filters}
              />
            </TableHeadFilterCell>
          );
        }
        case tableDataTypes.status.name:
        case tableDataTypes.isTop.name:
        case tableDataTypes.paymentType.name:
        case tableDataTypes.cancelStatus.name:
        case tableDataTypes.feedLocale.type:
        case tableDataTypes.orderStatus.name: {
          const filters = { value: getValue([]) };
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
              <StatusNameFilter
                columnName={column.name}
                key={key}
                filteringEnabled={filteringEnabled}
                filters={filters}
                onSelectChange={onSelectFilterChange}
              />
            </TableHeadFilterCell>
          );
        }
        case 'billingStatus': {
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
              <BillingStatusTableFilter
                disabled={!filteringEnabled}
                onChange={changeSelectFilter}
                value={get(filter, 'value', '')}
              />
            </TableHeadFilterCell>
          );
        }
        case 'brand': {
          const filters = { value: getValue() };
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
              <MultipleFilter
                columnType={column.type}
                columnName={column.name}
                columnValues={column.values}
                key={key}
                disabled={!filteringEnabled}
                filters={filters}
                onSelectChange={onSelectFilterChange}
              />
            </TableHeadFilterCell>
          );
        }
        case 'partner':
        case 'excludePartners':
        case 'idMagentoPartner': {
          const filters = { value: getValue([]) };
          return (
            <TableHeadFilterCell cellType={TABLE_CELL_FILTER.selectFilter} disabled={disabled} resetFilter={resetFilter}>
              <PartnerFilter
                key={key}
                disabled={!filteringEnabled}
                filters={filters}
                onSelectChange={onSelectFilterChange}
                columnName={column.name}
              />
            </TableHeadFilterCell>
          );
        }
        case brandsTableColumns.id.name:
        case brandsTableColumns.name.name:
        case tableDataTypes.feedCategory.name:
        case brandsTableColumns.idMagentoBrand.name: {
          let filterValue = getValue('');
          let isWithCategoryId = false;
          let defaultKey = getKey();

          if (column.name === tableDataTypes.feedCategory.name) {
            const currentId = path.substring(path.lastIndexOf('/') + 1);
            isWithCategoryId = path.indexOf(`/products/${currentId}`) !== -1;
            filterValue = isWithCategoryId ? currentId : filterValue;
            defaultKey = getKey(currentId);
          }

          return (
            <TextFieldTableFilter
              disabledIcon={disabled}
              disabled={!filteringEnabled}
              key={defaultKey}
              filterValue={filterValue}
              setFilterValue={onSelectFilterChange}
              resetFilter={resetFilter}
            />
          );
        }
        case logisticsDataTableColumns.categoryLogisticsLog.name: {
          return (
            <TextFieldTableFilter
              disabledIcon={disabled}
              disabled={!filteringEnabled}
              key={key}
              filterValue={getValue('')}
              setFilterValue={onSelectFilterChange}
              resetFilter={resetFilter}
              disabledPlaceholder
            />
          );
        }
        case 'toggle': {
          return (
            <TextFieldTableFilter
              disabledIcon={disabled}
              disabled={!filteringEnabled}
              key={key}
              filterValue={getValue('')}
              setFilterValue={onSelectFilterChange}
              resetFilter={resetFilter}
              disabledPlaceholder
            />
          );
        }
        case 'productUrl': {
          return (
            <TextFieldTableFilter
              disabledIcon={disabled}
              disabled={!filteringEnabled}
              key={key}
              filterValue={getValue('')}
              setFilterValue={onSelectFilterChange}
              resetFilter={resetFilter}
              disabledPlaceholder
            />
          );
        }
        case 'requiredLkValue': {
          return (
            <TableHeadFilterCell
              cellType={TABLE_CELL_FILTER.selectFilter}
              disabled={disabled}
              resetFilter={resetFilter}
            >
              <Select
                closeMenuOnScroll={column.closeMenuOnScroll}
                handleChange={changeValue}
                options={[{ value: 0, label: 'Вимкнено' }, { value: 1, label: 'Увімкнено' }]}
                value={getValue()}
                filterValueBy="value"
                label=""
                formSelectWidth="100%"
                wrapperClassName={cx(classes.flexGrowOne, classes.selectTableHead)}
                variant="standard"
              />
            </TableHeadFilterCell>
          );
        }
        default: {
          return (
            <TextFieldTableFilter
              disabledIcon={disabled}
              disabled={!filteringEnabled}
              key={key}
              filterValue={getValue('')}
              setFilterValue={onSelectFilterChange}
              resetFilter={resetFilter}
            />
          );
        }
      }
    }
  }
};

export default memo(TableFilter);
